import {
  LanguagePropertyResource,
  LanguageIndexResource,
  StatisticsWebcheckinStepsResource,
  StatisticsWebcheckinStepsByMonthResource,
  StatisticsWebcheckinEmailResource,
  PorReportResource,
  EnhancementsIndexResource,
  EnhancementsResource,
  TermsAndConditionsResource,
  TermsAndConditionsIndexResource,
  SettingsResource,
  SettingsSchema,
  ThemeResource,
  ThemePropertyResource,
  ThemeIndexResource,
  RoomcontrolResource,
  ReleaseAuthPaymentResource,
  ReservationsResource,
  ProfileResource,
  CheckinResource,
  UpdateDeleteUpsellItemResource,
  CreateUpsellItemResource,
  UpsellCondIndexResource,
  UpsellCondResource,
  CreateUpsellCondResource,
  SelfCheckinSettingsResource,
  SelfCheckinSettingsSchemaResource,
} from "./resources";

/*
  get: {method: 'GET'},
  save: {method: 'POST'},
  query: {method: 'GET'},
  update: {method: 'PUT'},
  remove: {method: 'DELETE'},
  delete: {method: 'DELETE'}
*/

export default {
  getSettings({ propertyId }) {
    return SettingsResource.get({ propertyId });
  },
  getSchema({ propertyId }) {
    return SettingsSchema.get({ propertyId });
  },
  updateSettings({ items, propertyId }) {
    return SettingsResource.update({ propertyId }, { ...items });
  },
  indexTheme({ propertyId }) {
    return ThemeIndexResource.get({
      propertyId,
    });
  },
  updateTheme({ id, item, propertyId }) {
    return ThemeResource.update(
      {
        id,
        propertyId,
      },
      item
    );
  },
  saveTheme({ item, propertyId, id }) {
    return ThemePropertyResource.save(
      {
        propertyId,
        id,
      },
      item
    );
  },
  deleteTheme({ id, propertyId }) {
    return ThemeResource.delete({
      id,
      propertyId,
    });
  },
  bulkDeleteTheme({ propertyId, items }) {
    return ThemePropertyResource.delete({ propertyId }, items);
  },
  releaseAuthPayment({ propertyId, authorizationId }) {
    return ReleaseAuthPaymentResource.save({ propertyId }, { authorizationId });
  },
  getPorReportSummary({ propertyId, month }) {
    return PorReportResource.save({ propertyId }, { month });
  },
  indexEnhancements({ propertyId }) {
    return EnhancementsIndexResource.get({
      propertyId,
    });
  },
  updateEnhancements({ _id, item, propertyId }) {
    return EnhancementsResource.update(
      {
        _id,
        propertyId,
      },
      item
    );
  },
  saveEnhancements({ item, propertyId }) {
    return EnhancementsResource.save(
      {
        propertyId,
      },
      item
    );
  },
  deleteEnhancements({ _id, propertyId }) {
    return EnhancementsResource.delete({
      _id,
      propertyId,
    });
  },
  indexUpsellCond({ propertyId }) {
    return UpsellCondIndexResource.get({
      propertyId,
    });
  },
  updateUpsellCond({ _id, propertyId, item }) {
    return UpsellCondResource.update(
      {
        id: _id,
        propertyId: propertyId,
      },
      item
    );
  },
  saveUpsellCond({ item, propertyId }) {
    return CreateUpsellCondResource.save(
      {
        propertyId,
      },
      item
    );
  },
  deleteUpsellCond({ _id, propertyId }) {
    return UpsellCondResource.delete({
      id: _id,
      propertyId: propertyId,
    });
  },
  saveUpsellItem({ propertyId, cond_id, item }) {
    return CreateUpsellItemResource.save(
      {
        condID: cond_id,
        propertyId: propertyId,
      },
      item
    );
  },
  updateUpsellItem({ cond_id, upsell_id, propertyId, item }) {
    return UpdateDeleteUpsellItemResource.update(
      {
        upsellID: upsell_id,
        condID: cond_id,
        propertyId: propertyId,
      },
      item
    );
  },
  deleteUpsellItem({ cond_id, upsell_id, propertyId }) {
    return UpdateDeleteUpsellItemResource.delete({
      upsellID: upsell_id,
      condID: cond_id,
      propertyId: propertyId,
    });
  },
  bulkDeleteEnhancements({ propertyId, items }) {
    return EnhancementsResource.delete({ propertyId }, items);
  },
  indexLanguage({ propertyId }) {
    return LanguageIndexResource.get({
      propertyId,
    });
  },
  saveLanguage({ items = [], propertyId }) {
    return LanguagePropertyResource.update(
      {
        propertyId,
      },
      items
    );
  },
  getWebcheckinStepsStatistics({ propertyId, dateFrom, dateTo }) {
    return StatisticsWebcheckinStepsResource.get({
      propertyId,
      dateFrom,
      dateTo,
    });
  },
  getWebcheckinStepsByMonthStatistics({
    propertyId,
    dateFrom,
    dateTo,
    stepName,
  }) {
    return StatisticsWebcheckinStepsByMonthResource.get({
      propertyId,
      dateFrom,
      dateTo,
      stepName,
    });
  },
  getWebcheckinEmailStatistics({ propertyId, from, to, productType, emailType }) {
    return StatisticsWebcheckinEmailResource.get({
      propertyId,
      from,
      to,
      productType,
      emailType,
    });
  },
  indexTermsAndConditions({ propertyId }) {
    return TermsAndConditionsIndexResource.get({
      propertyId,
    });
  },
  updateTermsAndConditions({ items, propertyId }) {
    return TermsAndConditionsResource.update(
      {
        propertyId,
      },
      {
        items,
      }
    );
  },
  indexTheme({ propertyId }) {
    return ThemeIndexResource.get({
      propertyId,
    });
  },
  indexRoomcontrol({ propertyId }) {
    return RoomcontrolResource.get({
      propertyId,
    });
  },
  updateRoomcontrol({ propertyId, item }) {
    return RoomcontrolResource.update(
      {
        propertyId,
      },
      item
    );
  },
  getReservations({ propertyId, pms, date }) {
    return ReservationsResource.get({ propertyId, pms, date });
  },
  profileUpdate({ propertyId, guestProfile, profileID = guestProfile.pmsId }) {
    return new Promise((resolve, reject) => {
      ProfileResource.update({ propertyId, profileID }, guestProfile).then(
        (response) => {
          resolve(response);
        },
        (response) => {
          reject(response);
        }
      );
    });
  },
  getProfile({ propertyId, checkinDate, lastName, confirmationNumber }) {
    return new Promise((resolve, reject) => {
      ProfileResource.save(
        { propertyId },
        {
          propertyId,
          checkinDate,
          lastName,
          confirmationNumber,
        }
      ).then(
        (response) => {
          resolve(response.body.reservationInfo);
        },
        (response) => {
          reject(response);
        }
      );
    });
  },
  checkin({ propertyId, checkinDate, lastname, confirmationNumber }) {
    return new Promise((resolve, reject) => {
      CheckinResource.save(
        { propertyId },
        {
          propertyId,
          checkinDate,
          lastname,
          confirmationNumber,
        }
      ).then(
        (response) => {
          resolve(response);
        },
        (response) => {
          reject(response);
        }
      );
    });
  },
  getSelfCheckinSettings({ propertyId }) {
    return SelfCheckinSettingsResource.get({ propertyId });
  },
  getSelfCheckinSettingsSchema({ propertyId }) {
    return SelfCheckinSettingsSchemaResource.get({ propertyId });
  },
  updateSelfCheckinSettings({ items, propertyId }) {
    return SelfCheckinSettingsResource.update({ propertyId }, { ...items });
  },
};
