import Vue from "vue";
import { API_ROOT_V3, API_PUBLIC_ROOT, API_ADIS_ROOT_V1 } from "../../config";

export const SettingsResource = Vue.resource(
  API_ROOT_V3 + "{propertyId}/oneapp/settings"
);
export const SettingsSchema = Vue.resource(
  API_ROOT_V3 + "{propertyId}/oneapp/settings/schema"
);
export const ThemeResource = Vue.resource(
  API_ROOT_V3 + "{propertyId}/oneapp/theme{/id}"
);
export const ThemeIndexResource = Vue.resource(
  API_ROOT_V3 + "{propertyId}/oneapp/theme/index"
);
export const ThemePropertyResource = Vue.resource(
  API_ROOT_V3 + "{propertyId}/oneapp/theme"
);
export const LanguagePropertyResource = Vue.resource(
  API_ROOT_V3 + "{propertyId}/oneapp/language"
);
export const LanguageIndexResource = Vue.resource(
  API_ROOT_V3 + "{propertyId}/oneapp/language/index"
);
export const StatisticsWebcheckinStepsResource = Vue.resource(
  API_ROOT_V3 + "{propertyId}/oneapp/statistics/webcheckinsteps"
);
export const StatisticsWebcheckinStepsByMonthResource = Vue.resource(
  API_ROOT_V3 + "{propertyId}/oneapp/statistics/webcheckinstep_by_month"
);
export const StatisticsWebcheckinEmailResource = Vue.resource(
  API_ADIS_ROOT_V1 + "sendgrid-event"
);
export const ReleaseAuthPaymentResource = Vue.resource(
  API_ROOT_V3 + "{propertyId}/oneapp/webcheckin/release_authorization"
);
export const PorReportResource = Vue.resource(
  API_ROOT_V3 + "{propertyId}/oneapp/webcheckin/por_report"
);
export const EnhancementsResource = Vue.resource(
  API_ROOT_V3 + "{propertyId}/oneapp/webcheckin/enhancements{/_id}"
);
export const EnhancementsIndexResource = Vue.resource(
  API_ROOT_V3 + "{propertyId}/oneapp/webcheckin/enhancements/index"
);

// Upsell Condition Routes
export const UpsellCondIndexResource = Vue.resource(
  API_ROOT_V3 + "{propertyId}/oneapp/webcheckin/upsells/index"
);
export const CreateUpsellCondResource = Vue.resource(
  API_ROOT_V3 + "{propertyId}/oneapp/webcheckin/upsells"
);
export const UpsellCondResource = Vue.resource(
  API_ROOT_V3 + "{propertyId}/oneapp/webcheckin/upsells/{id}"
);
// Upsell Item Routes
export const CreateUpsellItemResource = Vue.resource(
  API_ROOT_V3 + "{propertyId}/oneapp/webcheckin/upsells/{condID}/item"
);
export const UpdateDeleteUpsellItemResource = Vue.resource(
  API_ROOT_V3 +
    "{propertyId}/oneapp/webcheckin/upsells/{condID}/item/{upsellID}"
);

export const TermsAndConditionsResource = Vue.resource(
  API_ROOT_V3 + "{propertyId}/oneapp/terms"
);
export const TermsAndConditionsIndexResource = Vue.resource(
  API_ROOT_V3 + "{propertyId}/oneapp/terms/index"
);
export const RoomcontrolResource = Vue.resource(
  API_ROOT_V3 + "{propertyId}/oneapp/roomcontrol"
);
export const ReservationsResource = Vue.resource(
  API_ROOT_V3 + "{propertyId}/oneapp/webcheckin/{pms}/reservations/index"
);
export const ProfileResource = Vue.resource(
  API_ROOT_V3 +
    "{propertyId}/oneapp/webcheckin/protel/reservations/profile{/profileID}"
);
export const CheckinResource = Vue.resource(
  API_ROOT_V3 + "{propertyId}/oneapp/webcheckin/protel/reservations/checkin"
);
export const BTONSResource = Vue.resource(
  API_PUBLIC_ROOT + "v2/property{/propertyId}/webcheckin/send-guest-pdf",
  {},
  {},
  {
    emulateJSON: false,
  }
);
export const SelfCheckinSettingsResource = Vue.resource(
  API_ROOT_V3 + "{propertyId}/oneapp/self-checkin-settings"
);
export const SelfCheckinSettingsSchemaResource = Vue.resource(
  API_ROOT_V3 + "{propertyId}/oneapp/self-checkin-settings/schema"
);
