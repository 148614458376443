import api from "@api/oneapp";
import * as types from "../../types";
import Vue from "vue";

const EMAIL_TYPES = [
  "Pre-Stay",
  "Certificate of Registration",
  "Checkin Reminder",
  "Reference List",
  "Checkin Confirmation",
  "Checkout",
  "Pre-Registration",
  "Lokalee",
];

const state = {
  webcheckinSteps: [],
  webcheckinStepsByMonth: [],
  webcheckinEmails: [],
  statusEmails: void 0,
  statusSteps: void 0,
  statusStepsByMonth: void 0,
};

const getters = {
  emailTypes: () => {
    return EMAIL_TYPES.map((emailType) => {
      const type = emailType;
      const camelCaseType = type
        .toLowerCase()
        .replace(/[^a-zA-Z0-9]+(.)/g, (_, char) => char.toUpperCase());
      const label = Vue.i18n.translate(`oneapp.statistics.emailType.${camelCaseType}`);
      return { type, label };
    });
  },
  stepNames: () => [
    { step: "roomSelection", label: "oneapp.statistics.roomSelection" },
    { step: "upsells", label: "oneapp.statistics.upsells" },
    { step: "enhancement", label: "oneapp.statistics.enhancement" },
    { step: "checkin", label: "oneapp.statistics.checkin" },
    { step: "regCertificate", label: "oneapp.statistics.regCertificate" },
    { step: "checkout", label: "oneapp.statistics.checkout" },
    {
      step: "updateMethodOfPayment",
      label: "oneapp.statistics.updateMethodOfPayment",
    },
    { step: "depositPayment", label: "oneapp.statistics.depositPayment" },
    { step: "checkoutPayment", label: "oneapp.statistics.checkoutPayment" },
    { step: "preRegistered", label: "oneapp.statistics.preRegistered" },
  ],

  mappedWebcheckinSteps: (state) => {
    const stepsArray = getters.stepNames();

    return state.webcheckinSteps.map((item) => {
      const matchingStep = stepsArray.find(
        (stepObj) => stepObj.step === item.step
      );
      return {
        ...item,
        label: matchingStep ? matchingStep.label : item.step,
      };
    });
  },

  mappedEmailSteps: (state) => {
    const emailLabels = {
      delivered: "oneapp.statistics.delivered",
      open: "oneapp.statistics.open",
      processed: "oneapp.statistics.processed",
      click: "oneapp.statistics.click",
      dropped: "oneapp.statistics.dropped",
      deferred: "oneapp.statistics.deferred",
      bounce: "oneapp.statistics.bounce",
    };

    return state.webcheckinEmails.map((item) => ({
      label: emailLabels[Object.keys(item)[0]] || Object.keys(item)[0],
      count: Object.values(item)[0],
    }));
  },
};

const actions = {
  getWebcheckinStepsStatistics: async function (
    { commit },
    { propertyId, dateFrom, dateTo }
  ) {
    commit(types.REQUEST_WEBCHECKIN_STEPS);
    try {
      const response = await api.getWebcheckinStepsStatistics({
        propertyId,
        dateFrom,
        dateTo,
      });
      commit(types.SUCCESS_REQUEST_WEBCHECKIN_STEPS, response);
      return response;
    } catch (e) {
      commit(types.FAILURE_REQUEST_WEBCHECKIN_STEPS);
    }
  },
  getWebcheckinStepsByMonthStatistics: async function (
    { commit },
    { propertyId, dateFrom, dateTo, stepName }
  ) {
    commit(types.REQUEST_WEBCHECKIN_STEPS_BY_MONTH);
    try {
      const response = await api.getWebcheckinStepsByMonthStatistics({
        propertyId,
        dateFrom,
        dateTo,
        stepName,
      });
      commit(types.SUCCESS_REQUEST_WEBCHECKIN_STEPS_BY_MONTH, response);
      return response;
    } catch (e) {
      commit(types.FAILURE_REQUEST_WEBCHECKIN_STEPS_BY_MONTH);
    }
  },
  getWebcheckinEmailStatistics: async function (
    { commit },
    { propertyId, from, to, productType, emailType }
  ) {
    commit(types.REQUEST_WEBCHECKIN_EMAILS);
    try {
      const response = await api.getWebcheckinEmailStatistics({
        propertyId,
        from,
        to,
        productType,
        emailType,
      });
      commit(types.SUCCESS_REQUEST_WEBCHECKIN_EMAILS, response);
      return response;
    } catch (e) {
      commit(types.FAILURE_REQUEST_WEBCHECKIN_EMAILS);
    }
  },
};

const mutations = {
  [types.REQUEST_WEBCHECKIN_STEPS](state) {
    state.statusSteps = "fetching";
  },
  [types.FAILURE_REQUEST_WEBCHECKIN_STEPS](state) {
    state.statusSteps = "failure";
  },
  [types.SUCCESS_REQUEST_WEBCHECKIN_STEPS](state, value) {
    state.statusSteps = "success";
    state.webcheckinSteps = value.body.webcheckin_steps ?? [];
  },
  [types.REQUEST_WEBCHECKIN_STEPS_BY_MONTH](state) {
    state.statusStepsByMonth = "fetching";
  },
  [types.FAILURE_REQUEST_WEBCHECKIN_STEPS_BY_MONTH](state) {
    state.statusStepsByMonth = "failure";
  },
  [types.SUCCESS_REQUEST_WEBCHECKIN_STEPS_BY_MONTH](state, value) {
    state.statusStepsByMonth = "success";
    state.webcheckinStepsByMonth = value.body.daily_steps ?? [];
  },
  [types.REQUEST_WEBCHECKIN_EMAILS](state) {
    state.statusEmails = "fetching";
  },
  [types.FAILURE_REQUEST_WEBCHECKIN_EMAILS](state) {
    state.statusEmails = "failure";
  },
  [types.SUCCESS_REQUEST_WEBCHECKIN_EMAILS](state, value) {
    state.statusEmails = "success";
    state.webcheckinEmails = value.body ?? [];
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
