<template>
  <v-navigation-drawer
    width="320"
    v-if="
      $store.state.auth.info && Object.keys($store.state.auth.info).length > 0
    "
    :clipped="clipped"
    v-model="drawer"
    :fixed="fixed"
    app
  >
    <v-list dense expand nav>
      <template v-for="(item, i) in defaultItems">
        <navigation-drawer-list
          :key="'default-' + i"
          :item="item"
          :iterator="i"
        ></navigation-drawer-list>
      </template>

      <template v-for="(item, i) in adminItems">
        <navigation-drawer-list
          :key="'admin-' + i"
          :item="item"
          :iterator="i"
        ></navigation-drawer-list>
      </template>

      <template v-for="(item, i) in weatherItems">
        <navigation-drawer-list
          :key="'weather-' + i"
          :item="item"
          :iterator="i"
        ></navigation-drawer-list>
      </template>

      <v-divider></v-divider>

      <property-autocomplete></property-autocomplete>

      <template v-for="(item, i) in propertyDefault">
        <navigation-drawer-list
          v-if="$route.params.propertyId"
          :key="'property-' + item.title"
          :item="item"
          :iterator="i"
        ></navigation-drawer-list>
      </template>

      <template v-for="(item, i) in propertyManager">
        <navigation-drawer-list
          v-if="$route.params.propertyId"
          :key="'property-' + item.title"
          :item="item"
          :iterator="i"
        ></navigation-drawer-list>
      </template>

      <template v-for="(item, i) in checkinRoomManager">
        <navigation-drawer-list
          v-if="$route.params.propertyId"
          :key="'checkin-' + item.title"
          :item="item"
          :iterator="i"
        ></navigation-drawer-list>
      </template>

      <template v-for="(item, i) in roomcontrol">
        <navigation-drawer-list
          v-if="$route.params.propertyId"
          :key="'checkin-' + item.title"
          :item="item"
          :iterator="i"
        ></navigation-drawer-list>
      </template>

      <template>
        <template v-if="$route.params.propertyId">
          <navigation-drawer-list
            v-for="(item, i) in propertyContent"
            :key="'property-' + i"
            :item="item"
            :iterator="i"
          ></navigation-drawer-list>
          <v-divider></v-divider>
          <v-subheader>{{
            $t("components.core.NavigationDrawer.myProducts")
          }}</v-subheader>
        </template>
      </template>

      <template>
        <template v-for="(item, i) in onespeed">
          <navigation-drawer-list
            v-if="$route.params.propertyId"
            :key="'onespeed-' + i"
            :item="item"
            :iterator="i"
          ></navigation-drawer-list>
        </template>
      </template>

      <template>
        <template v-for="(item, i) in onestream">
          <navigation-drawer-list
            v-if="$route.params.propertyId"
            :key="'onestream-' + i"
            :item="item"
            :iterator="i"
          ></navigation-drawer-list>
        </template>
      </template>

      <template>
        <template v-for="(item, i) in oneapp">
          <navigation-drawer-list
            v-if="$route.params.propertyId"
            :key="'oneapp-' + i"
            :item="item"
            :iterator="i"
          ></navigation-drawer-list>
        </template>
      </template>

      <template>
        <template v-for="(item, i) in onekey">
          <navigation-drawer-list
            v-if="$route.params.propertyId"
            :key="'onekey-' + i"
            :item="item"
            :iterator="i"
          ></navigation-drawer-list>
        </template>
      </template>

      <template>
        <template v-for="(item, i) in onesquare">
          <navigation-drawer-list
            v-if="$route.params.propertyId"
            :key="'onesquare-' + i"
            :item="item"
            :iterator="i"
          ></navigation-drawer-list>
        </template>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { SET_DRAWER } from "@store/types";
import { mapMutations, mapState, mapActions, mapGetters } from "vuex";
import includes from "lodash/includes";
import PropertyAutocomplete from "@/components/common/PropertyAutocomplete";
import NavigationDrawerList from "@/components/core/NavigationDrawerList";

export default {
  name: "NavigationDrawer",
  components: {
    PropertyAutocomplete,
    NavigationDrawerList,
  },
  data() {
    return {
      isEditing: false,
      selected: void 0,
      propertyFilterTerm: "",
      fixed: true,
      clipped: true,
      miniVariant: false,
      right: false,
      rightDrawer: false,
    };
  },
  methods: {
    ...mapMutations({
      setDrawer: SET_DRAWER,
    }),
    ...mapActions("property", ["setSelectedProperty"]),
    customFilter(value) {
      return includes(
        value.name.toLowerCase(),
        this.propertyFilterTerm.toLowerCase()
      );
    },
  },
  computed: {
    ...mapState({
      properties: ({ property }) => property.items,
    }),
    ...mapGetters("auth", ["getPermissions"]),
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        this.setDrawer(val);
      },
    },
    selectedProperty: {
      get() {
        return this.$store.state.property.selectedProperty;
      },
      set(val) {
        this.$router.push(
          this.$route.meta.product
            ? { params: { propertyId: val } }
            : { name: "propertyDashboard", params: { propertyId: val } }
        );
      },
    },
    defaultItems() {
      return [
        {
          title: this.$t("components.core.NavigationDrawer.home"),
          icon: "mdi-home",
          exact: true,
          property: false,
          to: {
            name: "home",
          },
        },
      ];
    },
    adminItems() {
      const ret = {
        icon: "mdi-account",
        group: "super",
        title: this.$t("components.core.NavigationDrawer.admin"),
        items: [],
      };

      if (this.getPermissions.includes("admin.properties.list")) {
        ret.items.push({
          title: this.$t("properties"),
          icon: "mdi-domain",
          to: {
            name: "properties",
          },
        });
      }
      if (this.getPermissions.includes("admin.propertyGroups.list")) {
        ret.items.push({
          title: this.$t("components.core.NavigationDrawer.propertyGroup"),
          icon: "mdi-home-group",
          to: {
            name: "propertyGroup",
          },
        });
      }
      if (this.getPermissions.includes("admin.roles.list")) {
        ret.items.push({
          title: this.$t("components.core.NavigationDrawer.roles"),
          icon: "mdi-card-account-details-outline",
          to: {
            name: "roles",
          },
        });
      }
      if (this.getPermissions.includes("admin.user.list")) {
        ret.items.push({
          title: this.$t("components.core.NavigationDrawer.users"),
          icon: "mdi-account-group",
          to: {
            name: "superUsers",
          },
        });
      }
      if (this.getPermissions.includes("admin.heartbeat.list")) {
        ret.items.push({
          title: this.$t("components.core.NavigationDrawer.heartbeat"),
          icon: "mdi-domain",
          to: {
            name: "heartbeat",
          },
        });
      }

      if (!ret || ret.items.length < 1) {
        return [];
      }

      return [ret];
    },
    weatherItems() {
      const ret = {
        icon: "mdi-weather-cloudy",
        group: "weather",
        title: this.$t("components.core.NavigationDrawer.weather"),
        items: [],
      };

      if (this.getPermissions.includes("admin.weather.locations.list")) {
        ret.items.push({
          title: this.$t("components.core.NavigationDrawer.locations"),
          icon: "mdi-crosshairs-gps",
          to: {
            name: "locations",
          },
        });
      }
      if (this.getPermissions.includes("admin.weather.settings.get")) {
        ret.items.push({
          title: this.$t("components.core.NavigationDrawer.settings"),
          icon: "mdi-cog-outline",
          to: {
            name: "settings",
          },
        });
      }

      if (!ret || ret.items.length < 1) {
        return [];
      }

      return [ret];
    },
    propertyDefault() {
      return [
        {
          title: this.$t("components.core.NavigationDrawer.propertyDashboard"),
          icon: "mdi-view-dashboard",
          exact: true,
          to: {
            name: "propertyDashboard",
          },
        },
      ];
    },
    propertyManager() {
      const ret = {
        icon: "mdi-cog-outline",
        group: "admin",
        title: this.$t("components.core.NavigationDrawer.propertySettings"),
        items: [],
      };

      if (this.getPermissions.includes("property.settings.license.list")) {
        ret.items.push({
          title: this.$t("components.core.NavigationDrawer.license"),
          icon: "mdi-account-card-details",
          to: { name: "license" },
        });
      }
      if (this.getPermissions.includes("property.user.list")) {
        ret.items.push({
          title: this.$t("components.core.NavigationDrawer.users"),
          icon: "mdi-account-group",
          to: {
            name: "propertyUsers",
          },
        });
      }

      if (!ret || ret.items.length < 1) {
        return [];
      }

      return [ret];
    },
    checkinRoomManager() {
      let ret = {};

      if (this.getPermissions.includes("property.checkinRoomManager.list")) {
        ret = {
          title: this.$t("components.core.NavigationDrawer.checkinRoomManager"),
          icon: "mdi-bed",
          exact: true,
          to: {
            name: "checkinRoomManager",
          },
        };
      }

      if (Object.keys(ret).length < 1) {
        return [];
      }

      return [ret];
    },
    roomcontrol() {
      let ret = {};

      if (this.getPermissions.includes("property.roomControl.list")) {
        ret = {
          title: this.$t("components.core.NavigationDrawer.roomcontrol"),
          icon: "mdi-lightbulb",
          exact: true,
          to: {
            name: "oneappRoomcontrol",
          },
        };
      }

      if (Object.keys(ret).length < 1) {
        return [];
      }

      return [ret];
    },
    propertyContent() {
      let ret = {};

      if (this.getPermissions.includes("content.mediaPool.list")) {
        ret = {
          icon: "mdi-brush",
          group: "content-management",
          title: this.$t("components.core.NavigationDrawer.contentManagement"),
          items: [
            {
              title: this.$t("mediapool"),
              icon: "mdi-folder-multiple-image",
              to: { name: "mediapool" },
            },
          ],
        };
      }

      if (Object.keys(ret).length < 1) {
        return [];
      }

      return [ret];
    },
    onespeed() {
      let ret = undefined;
      if (
        this.getPermissions.includes("onespeed.stats.dashboard") ||
        this.getPermissions.includes("onespeed.profiles.list") ||
        this.getPermissions.includes("onespeed.allowList.list") ||
        this.getPermissions.includes("onespeed.blockList.list") ||
        this.getPermissions.includes("onespeed.tickets.list") ||
        this.getPermissions.includes("onespeed.bulkTickets.list") ||
        this.getPermissions.includes("onespeed.termsAndConditions.get") ||
        this.getPermissions.includes("onespeed.themes.list") ||
        this.getPermissions.includes("onespeed.language.get") ||
        this.getPermissions.includes("onespeed.settings.show")
      ) {
        ret = {
          afterSubheader: true,
          avatar: require("@/assets/ONEspeed Logo_klein.png"),
          group: "onespeed-gateway",
          title: this.$t("components.core.NavigationDrawer.onespeed"),
          items: [],
        };
      }

      if (this.getPermissions.includes("onespeed.stats.dashboard")) {
        ret.items.push({
          title: this.$t("components.core.NavigationDrawer.dashboard"),
          icon: "mdi-view-dashboard",
          to: { name: "onespeedGatewayDashboard" },
        });
      }

      if (this.getPermissions.includes("onespeed.profiles.list")) {
        ret.items.push({
          title: this.$t("components.core.NavigationDrawer.monitoring"),
          icon: "mdi-monitor",
          to: { name: "onespeedGatewayMonitoring" },
        });
      }
      if (this.getPermissions.includes("onespeed.allowList.list")) {
        ret.items.push({
          title: this.$t("components.core.NavigationDrawer.whitelist"),
          icon: "mdi-cellphone-nfc",
          to: { name: "onespeedGatewayWhitelist" },
        });
      }
      if (this.getPermissions.includes("onespeed.blockList.list")) {
        ret.items.push({
          title: this.$t("components.core.NavigationDrawer.blocklist"),
          icon: "mdi-cellphone-nfc-off",
          to: { name: "onespeedGatewayBlocklist" },
        });
      }
      if (this.getPermissions.includes("onespeed.tickets.list")) {
        ret.items.push({
          title: this.$t("tickets"),
          icon: "mdi-ticket-outline",
          to: { name: "onespeedGatewayTicket" },
        });
      }
      if (this.getPermissions.includes("onespeed.bulkTickets.list")) {
        ret.items.push({
          title: this.$t("components.core.NavigationDrawer.bulkTickets"),
          icon: "mdi-ticket",
          to: { name: "onespeedGatewayBulkTickets" },
        });
      }
      if (this.getPermissions.includes("onespeed.termsAndConditions.get")) {
        ret.items.push({
          title: this.$t("components.core.NavigationDrawer.termsAndConditions"),
          icon: "mdi-clipboard-check",
          to: { name: "onespeedGatewayTermsAndConditions" },
        });
      }
      if (this.getPermissions.includes("onespeed.themes.list")) {
        ret.items.push({
          title: this.$t("components.core.NavigationDrawer.themes"),
          icon: "mdi-brush",
          to: { name: "onespeedGatewayTheme" },
        });
      }
      if (this.getPermissions.includes("onespeed.language.get")) {
        ret.items.push({
          title: this.$t("language.language"),
          icon: "mdi-web",
          to: { name: "onespeedGatewayLanguage" },
        });
      }
      if (this.getPermissions.includes("onespeed.settings.show")) {
        ret.items.push({
          title: this.$t("components.core.NavigationDrawer.settings"),
          icon: "mdi-cog-outline",
          to: { name: "onespeedGatewaySettings" },
        });
      }

      if (!ret || ret.items.length < 1) {
        return [];
      }

      return [ret];
    },
    onestream() {
      let ret = undefined;
      if (
        this.getPermissions.includes("onestream.stats.list") ||
        this.getPermissions.includes("onestream.settings.get") ||
        this.getPermissions.includes("onestream.devices.list")
      ) {
        ret = {
          avatar: require("@/assets/ONEsquare_001.png"),
          group: "onestream",
          title: this.$t("components.core.NavigationDrawer.onestream"),
          items: [],
        };
      }

      if (this.getPermissions.includes("onestream.stats.list")) {
        ret.items.push({
          title: this.$t("components.core.NavigationDrawer.dashboard"),
          icon: "mdi-view-dashboard",
          to: { name: "onestreamDashboard" },
        });
      }
      if (this.getPermissions.includes("onestream.settings.get")) {
        ret.items.push({
          title: this.$t("components.core.NavigationDrawer.settings"),
          icon: "mdi-cog-outline",
          to: { name: "onestreamSettings" },
        });
      }
      if (this.getPermissions.includes("onestream.devices.list")) {
        ret.items.push({
          title: this.$t("components.core.NavigationDrawer.devices"),
          icon: "mdi-cast",
          to: { name: "onestreamDevices" },
        });
      }

      if (!ret || ret.items.length < 1) {
        return [];
      }

      return [ret];
    },
    oneapp() {
      let ret = undefined;
      if (
        this.getPermissions.includes("oneapp.language.get") ||
        this.getPermissions.includes("oneapp.settings.show") ||
        this.getPermissions.includes("oneapp.reservations.list") ||
        this.getPermissions.includes("oneapp.webcheckin.enhancements.list") ||
        this.getPermissions.includes("oneapp.termsAndConditions.get") ||
        this.getPermissions.includes("oneapp.themes.list") ||
        this.getPermissions.includes("oneapp.self-checkin.settings.show")
      ) {
        ret = {
          avatar: require("@/assets/oneapp.png"),
          group: "oneapp",
          title: this.$t("components.core.NavigationDrawer.oneapp"),
          items: [
            {
              title: this.$t("components.core.NavigationDrawer.dashboard"),
              icon: "mdi-view-dashboard",
              to: { name: "oneappDashboard" },
            },
          ],
        };
      }
      if (this.getPermissions.includes("oneapp.themes.list")) {
        ret.items.push({
          title: this.$t("components.core.NavigationDrawer.themes"),
          icon: "mdi-brush",
          to: { name: "oneappTheme" },
        });
      }
      if (this.getPermissions.includes("oneapp.termsAndConditions.get")) {
        ret.items.push({
          title: this.$t("components.core.NavigationDrawer.termsAndConditions"),
          icon: "mdi-clipboard-check",
          to: { name: "oneappTermsAndConditions" },
        });
      }
      if (
        this.getPermissions.includes("oneapp.webcheckin.enhancements.list") ||
        this.getPermissions.includes("oneapp.webcheckin.por_report") ||
        this.getPermissions.includes("oneapp.webcheckin.release_authorization")
      ) {
        let webcheckin = {
          group: "oneapp/webcheckin",
          title: "Webcheckin",
          items: [],
        };
        if (
          this.getPermissions.includes("oneapp.webcheckin.enhancements.list")
        ) {
          webcheckin.items.push({
            title: this.$t("components.core.NavigationDrawer.enhancements"),
            icon: "mdi-currency-usd",
            to: { name: "oneappWebcheckinEnhancements" },
          });
        }
        if (this.getPermissions.includes("oneapp.webcheckin.upsells.list")) {
          webcheckin.items.push({
            title: this.$t("components.core.NavigationDrawer.rewards"),
            icon: "mdi-basket-fill",
            to: { name: "oneappWebcheckinUpsells" },
          });
        }
        if (this.getPermissions.includes("oneapp.webcheckin.por_report")) {
          webcheckin.items.push({
            title: this.$t("components.core.NavigationDrawer.porReport"),
            icon: "mdi-home-analytics",
            to: { name: "oneappWebcheckinPorReport" },
          });
        }
        if (
          this.getPermissions.includes(
            "oneapp.webcheckin.release_authorization"
          )
        ) {
          webcheckin.items.push({
            title: this.$t("oneapp.releaseAuthPayment.titleShort"),
            icon: "mdi-account-cash",
            to: { name: "oneappWebcheckinReleaseAuthPayment" },
          });
        }
        if (
          this.getPermissions.includes("oneapp.reservations.list") &&
          this.getPermissions.includes("oneapp.themes.list")
        ) {
          webcheckin.items.push({
            title: this.$t("components.core.NavigationDrawer.reservations"),
            icon: "mdi-account-check",
            to: { name: "oneappWebcheckinReservations" },
          });
        }
        ret.items.push(webcheckin);
      }
      if (this.getPermissions.includes("oneapp.language.get")) {
        ret.items.push({
          title: this.$t("language.language"),
          icon: "mdi-web",
          to: { name: "oneappLanguage" },
        });
      }
      if (this.getPermissions.includes("oneapp.settings.show")) {
        ret.items.push({
          title: this.$t("components.core.NavigationDrawer.settings"),
          icon: "mdi-cog-outline",
          to: { name: "oneappSettings" },
        });
      }
      if (this.getPermissions.includes("oneapp.self-checkin.settings.show")) {
        ret.items.push({
          title: this.$t("components.core.NavigationDrawer.selfCheckinSettings"),
          icon: "mdi-cog-outline",
          to: { name: "oneappSelfCheckinSettings" },
        });
      }
      if (!ret || ret.items.length < 1) {
        return [];
      }

      return [ret];
    },
    onekey() {
      let ret = undefined;
      if (
        this.getPermissions.includes(
          "onekey.infoText.get" || "onekey.language.get"
        )
      ) {
        ret = {
          afterSubheader: true,
          avatar: require("@/assets/onekey.png"),
          group: "onekey",
          title: this.$t("components.core.NavigationDrawer.onekey"),
          items: [],
        };
      }

      if (this.getPermissions.includes("onekey.infoText.get")) {
        ret.items.push({
          title: this.$t("views.onekey.infoText.mainTitle"),
          icon: "mdi-information",
          to: { name: "onekeyInfoText" },
        });
      }

      if (this.getPermissions.includes("onekey.language.get")) {
        ret.items.push({
          title: this.$t("language.language"),
          icon: "mdi-web",
          to: { name: "onekeyLanguage" },
        });
      }

      if (!ret || ret.items.length < 1) {
        return [];
      }

      return [ret];
    },
    onesquare() {
      let ret = undefined;

      if (this.getPermissions.includes("onesquare.settings.get")) {
        ret = {
          avatar: require("@/assets/ONEsquare_001.png"),
          group: "onesquare",
          title: this.$t("components.core.NavigationDrawer.onesquare"),
          items: [],
        };
      }

      if (this.getPermissions.includes("onesquare.settings.get")) {
        ret.items.push({
          title: this.$t("components.core.NavigationDrawer.settings"),
          icon: "mdi-cog-outline",
          to: { name: "onesquareSettings" },
        });
      }

      if (!ret || ret.items.length < 1) {
        return [];
      }

      return [ret];
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.setDrawer(!this.$vuetify.breakpoint.mdAndDown);
    });
  },
  watch: {
    "$route.params.property"() {
      this.$forceUpdate();
    },
  },
};
</script>
