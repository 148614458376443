const Toolbar = () => import("@/components/core/Toolbar");
const Settings = () => import("@/views/oneapp/Settings");
//const Dashboard = () => import("@/views/Dashboard");
const Themes = () => import("@/views/oneapp/Themes");
const Dashboard = () => import("@/views/oneapp/Dashboard");
const TermsAndConditions = () => import("@/views/oneapp/TermsAndConditions");
const Language = () => import("@/views/oneapp/Language");
const WebcheckinEnhancements = () => import("@/views/oneapp/Enhancements");
const WebcheckinUpsells = () => import("@/views/oneapp/Upsells");
const WebcheckinPorReport = () => import("@/views/oneapp/PorReport");
const WebcheckinReleaseAuthPayment = () =>
  import("@/views/oneapp/ReleaseAuthPayment");
const Reservations = () => import("@/views/oneapp/Reservations.vue");
const SelfCheckinSettings = () => import("@/views/oneapp/SelfCheckinSettings");
const title = "ONEapp";
const key = "oneapp";
import webcheckinRoutes from "./webcheckin";

export default [
  {
    path: "/property/:propertyId/oneapp",
    name: "oneappDashboard",
    components: {
      default: Dashboard,
      toolbar: Toolbar,
    },
    meta: {
      key,
      product: true,
      title,
    },
  },
  {
    path: "/property/:propertyId/oneapp/settings",
    name: "oneappSettings",
    components: {
      default: Settings,
      toolbar: Toolbar,
    },
    meta: {
      key,
      product: true,
      title,
    },
  },
  {
    path: "/property/:propertyId/oneapp/theme",
    name: "oneappTheme",
    components: {
      default: Themes,
      toolbar: Toolbar,
    },
    meta: {
      key,
      product: true,
      title,
    },
  },
  {
    path: "/property/:propertyId/oneapp/terms/index",
    name: "oneappTermsAndConditions",
    components: {
      default: TermsAndConditions,
      toolbar: Toolbar,
    },
    meta: {
      key,
      product: true,
      title,
    },
  },
  {
    path: "/property/:propertyId/oneapp/language/index",
    name: "oneappLanguage",
    components: {
      default: Language,
      toolbar: Toolbar,
    },
    meta: {
      key,
      product: true,
      title,
    },
  },
  {
    path: "/property/:propertyId/oneapp/webcheckin/enhancements",
    name: "oneappWebcheckinEnhancements",
    components: {
      default: WebcheckinEnhancements,
      toolbar: Toolbar,
    },
    meta: {
      key,
      product: true,
      title,
    },
  },
  {
    path: "/property/:propertyId/oneapp/webcheckin/upsells",
    name: "oneappWebcheckinUpsells",
    components: {
      default: WebcheckinUpsells,
      toolbar: Toolbar,
    },
    meta: {
      key,
      product: true,
      title,
    },
  },
  {
    path: "/property/:propertyId/oneapp/webcheckin/por_report",
    name: "oneappWebcheckinPorReport",
    components: {
      default: WebcheckinPorReport,
      toolbar: Toolbar,
    },
    meta: {
      key,
      product: true,
      title,
    },
  },
  {
    path: "/property/:propertyId/oneapp/webcheckin/release_authorization",
    name: "oneappWebcheckinReleaseAuthPayment",
    components: {
      default: WebcheckinReleaseAuthPayment,
      toolbar: Toolbar,
    },
    meta: {
      key,
      product: true,
      title,
    },
  },
  {
    path: "/property/:propertyId/oneapp/webcheckin/reservations",
    name: "oneappWebcheckinReservations",
    components: {
      default: Reservations,
      toolbar: Toolbar,
    },
    meta: {
      key,
      product: true,
      title,
    },
  },
  {
    path: "/property/:propertyId/oneapp/self-checkin-settings",
    name: "oneappSelfCheckinSettings",
    components: {
      default: SelfCheckinSettings,
      toolbar: Toolbar,
    },
    meta: {
      key,
      product: true,
      title,
    },
  },
  ...webcheckinRoutes,
];
